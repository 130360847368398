<template>
  <!-- 讲师申请记录 -->
  <div class="component-tit">{{ $t('teacher.lecturer_application_record') }}</div>
  <div class="table-wrap">
    <div class="apply-status" v-if="teacherStatus != 1 && teacherStatus != 2">
      <!-- 当前讲师申请正在审核中，请耐心等待 -->
      <p v-if="applyStatus == 2">{{ $t('teacher.lecturer_application_is_under_review_please_be_patient') }}</p>
      <!-- 讲师申请信息审核驳回，请重新 -->
      <p v-else-if="teacherStatus == 3">
        <!-- 讲师申请信息审核驳回，请重新 -->
        {{ $t('teacher.lecturer_application_information_audit_rejected_please_again') }}
        <!-- 提交 -->
        <strong @click="apply">{{ $t('CM_Submit') }}</strong>
        <!-- 讲师申请 -->
        {{ $t('mine.lecturer_apply') }}
      </p>
    </div>
    <a-table
      :scroll="{ x: 884 }"
      :columns="columns"
      rowKey="applyId"
      :data-source="dataList"
      :pagination="false"
    >
      <template #folder="{ record }">
        <a-tooltip placement="topLeft">
          <template #title>{{ record.folderPath }}</template>
          {{ record.folderName }}
        </a-tooltip>
      </template>
      <template #attachments="{ record }">
        <a-tooltip placement="topLeft">
          <template #title>
            <template v-if="record.attachments[0].secretData">
              {{ htsySecret[record.attachments[0].secretData] }}
            </template>
            {{
              record.attachments[0].fileName +
              record.attachments[0].filePath.substr(
                record.attachments[0].filePath.lastIndexOf(".")
              )
            }}
          </template>
          <span class="down" @click="viewOrDownFile(record.attachments[0])">
            <template v-if="record.attachments[0].secretData">
              {{ htsySecret[record.attachments[0].secretData] }}
            </template>
            {{
              record.attachments[0].fileName +
              record.attachments[0].filePath.substr(
                record.attachments[0].filePath.lastIndexOf(".")
              )
            }}
          </span>
        </a-tooltip>
      </template>
      <template #applyTime="{ text }">
        <span>{{ dateFormat(text) }}</span>
      </template>
      <template #status="{ text }">
        <!-- 通过 -->
        <span v-if="text == 1">{{ $t('CM_Pass') }}</span>
        <!-- 已驳回 -->
        <span v-else-if="text == 3">{{ $t('teacher.rejected') }}</span>
        <!-- 审核中 -->
        <span v-else>{{ $t('teacher.under_review') }}</span>
      </template>
      <template #action="{ record }">
        <div class="action">
          <!-- 查看 -->
          <a-button type="link" @click="getDetail(record.applyId)"
            >{{ $t('cm_view') }}</a-button
          >
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { reactive, toRefs, computed } from "vue";
import { useStore } from "vuex";
import { lecturerApplyList } from "@/api/teacher";
import { dateFormat, viewOrDownFile } from "@/utils/tools";
import { htsySecret } from "@/utils/business";
export default {
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const store = useStore();
    const state = reactive({
      columns: [
        {
          // title: "申请等级",
          title: $t('teacher.apply_level'),
          dataIndex: "levelName",
          width: 150,
          ellipsis: true,
        },
        {
          // title: "讲师分类",
          title: $t('teacher.lecturer_classification'),
          width: 200,
          ellipsis: true,
          slots: { customRender: "folder" },
        },
        {
          // title: "课件附件",
          title: $t('teacher.attachment'),
          width: 250,
          ellipsis: true,
          slots: { customRender: "attachments" },
        },
        {
          // title: "申请状态",
          title: $t('teacher.apply_status'),
          dataIndex: "status",
          width: 150,
          slots: { customRender: "status" },
        },
        {
          // title: "申请时间",
          title: $t('teacher.apply_time'),
          dataIndex: "applyTime",
          width: 170,
          slots: { customRender: "applyTime" },
        },
        {
          // title: "操作",
          title: $t('cm_operate'),
          dataIndex: "action",
          width: 100,
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
      dataList: [],
      teacherStatus: computed(() => store.getters.isTeacher),
      applyStatus: 0, // 1 通过  2 审核中  3 已驳回
    });

    lecturerApplyList().then((res) => {
      state.dataList = res.data || [];
      if (state.dataList.length) {
        state.applyStatus = state.dataList[0].status || 2;
      }
    });

    const apply = () => {
      emit("changeKey", {
        key: "apply",
        hasBack: true,
      });
    };

    const getDetail = (id) => {
      emit("changeKey", {
        key: "detail",
        applyId: id,
      });
    };

    return {
      dateFormat,
      ...toRefs(state),
      apply,
      getDetail,
      htsySecret,
      viewOrDownFile,
    };
  },
};
</script>

<style lang="less" scoped>
.component-tit {
  font-size: 18px;
  line-height: 18px;
  color: #333;
  font-weight: bold;
  padding-left: 6px;
  margin: 4px 0 10px;
  border-left: 6px solid @color-theme;
}
.table-wrap {
  padding-top: 10px;
  .apply-status {
    margin-bottom: 14px;
    p {
      margin: 0;
      line-height: 24px;
      strong {
        font-weight: normal;
        color: @color-theme;
        cursor: pointer;
      }
    }
  }
  .down {
    color: @color-theme;
    cursor: pointer;
  }
  ::v-deep(.ant-table-pagination.ant-pagination) {
    float: none;
    text-align: center;
  }
  .action {
    ::v-deep(.ant-btn) {
      padding-right: 4px;
      padding-left: 0;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>