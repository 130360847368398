<template>
  <div class="component-wrap">
    <apply v-if="key == 'apply'" :hasBack="hasBack" @changeKey="change" />
    <list v-else-if="key == 'list'" @changeKey="change" />
    <detail
      v-else-if="key == 'detail'"
      :applyId="applyId"
      @changeKey="change"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import apply from "./teacher/apply.vue";
import list from "./teacher/list.vue";
import detail from "./teacher/detail.vue";
export default {
  components: {
    apply,
    list,
    detail,
  },
  setup() {
    const store = useStore();
    const key = ref("apply");
    const hasBack = ref(false);
    const applyId = ref(0);

    watch(
      () => store.getters.isTeacher,
      (status) => {
        if (status == 1 || status == 3) {
          key.value = "list";
        }
      },
      { immediate: true }
    );

    const change = (e) => {
      key.value = e.key;
      applyId.value = e.applyId || 0;
      hasBack.value = e.hasBack || false;
    };

    return {
      key,
      applyId,
      hasBack,
      change,
    };
  },
};
</script>

<style lang="less" scoped>
.component-wrap {
  min-height: 100%;
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
}
</style>
